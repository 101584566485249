import { Link } from 'gatsby'
import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';
import SimpleNav from '../../simple-nav'
import vimpatLogo from '../../../images/vimpat-logo.png'
import briviactLogo from '../../../images/briviact-logo.png'

const Header = () => (
  <div className="outer-container">
    <div className="inner-container">
    <Grid fluid>
      <Row>
        <Col xs={12} sm={4} md={4}>
          <Link to="/">
            <img src={vimpatLogo} alt="Logo" className="logo"/>
            <img src={briviactLogo} alt="Logo" className="logo briviact-logo"/>
          </Link>
        </Col>
      </Row>
    </Grid>

    </div>
  </div>
)

export default Header
