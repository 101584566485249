import ActiveLink from '../active-link'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import EventEmitter from '../../lib/emitter'
//import navList from './nav-config'

import './simple-nav.scss'

const handleClick = () =>  {
  EventEmitter.emit('CLOSE_MOBILEMENU', true)
}
{
  /**
   * Refer to ./nav-config.js to update menu values.
   * Desktop menu specific styles are included in ./simple-nav.scss
   * */
}
const SimpleNav = props => (
  <div>
      <ul className={`main-nav ${props.className}`}>
        <li>
          <a onClick={()=>handleClick()} href="https://www.vimpat.com/vimpat-prescribing-information.pdf" target="_blank">VIMPAT Full Prescribing Information</a>
        </li>
        <li>
          <AnchorLink href="#isi_jump" offset='60' onClick={()=>handleClick()}>VIMPAT Important Safety Information</AnchorLink>
        </li>
      </ul>
      <ul className={`main-nav ${props.className} briviact-link`}>
        <li>
          <a onClick={()=>handleClick()} href="https://www.briviact.com/briviact-PI.pdf" target="_blank">BRIVIACT Full Prescribing Information</a>
        </li>
        <li>
          <AnchorLink href="https://www.briviact.com/" offset='60' onClick={()=>handleClick()}>BRIVIACT Important Safety Information</AnchorLink>
        </li>

    </ul>
  </div>
  
)

export default SimpleNav
