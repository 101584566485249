import React from 'react'
import ActiveLink from '../../active-link'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { copyrightYear } from '../../../lib/util.helper'
import ucbLogo from '../../../images/ucb-logo.png'
import ucbCaresLogo from '../../../images/ucbcares-logo.png'

// import imgEndoLogo from '../../../images/endo-logo.png'
import './footer.scss'  

const Footer = () => (
  <div className="outer-container footer-container">
    <div className="inner-container">
      <Grid fluid>
        <Row>
          <Col xs={9}>
            <Row>
              <Col xs={12}>
                <div className="footer-links-block">
                  <ActiveLink
                    EnableActiveClass={false}
                    ExternalLink
                    to="https://www.vimpat.com/privacy-policy"
                    EventObject={{
                      category: 'Footer Link',
                      action: 'Click',
                      label: 'Privacy Policy Link',
                    }}
                  >
                    VIMPAT Privacy Policy
                  </ActiveLink>
                  <span className="footer-link-separator">|</span>
                  <ActiveLink
                    EnableActiveClass={false}
                    ExternalLink
                    to="https://www.vimpat.com/terms-conditions"
                    EventObject={{
                      category: 'Footer Link',
                      action: 'Click',
                      label: 'Terms of Use Link',
                    }}
                  >
                      VIMPAT Terms of Use
                  </ActiveLink>
                  <span className="footer-link-separator">|</span>
                  <ActiveLink
                    EnableActiveClass={false}
                    ExternalLink
                    to="https://www.briviact.com/privacy-policy"
                    EventObject={{
                      category: 'Footer Link',
                      action: 'Click',
                      label: 'Privacy Policy Link',
                    }}
                  >
                    BRIVIACT Privacy Policy
                  </ActiveLink>
                  <span className="footer-link-separator">|</span>
                  <ActiveLink
                    EnableActiveClass={false}
                    ExternalLink
                    to="https://www.briviact.com/terms-of-use"
                    EventObject={{
                      category: 'Footer Link',
                      action: 'Click',
                      label: 'Terms of Use Link',
                    }}
                  >
                      BRIVIACT Terms of Use
                  </ActiveLink>
                  <span className="footer-link-separator">|</span>
                  <ActiveLink
                    EnableActiveClass={false}
                    ExternalLink
                    to="http://www.askucbcares.com/"
                    EventObject={{
                      category: 'Footer Link',
                      action: 'Click',
                      label: 'Contact Us Link',
                    }}
                  >
                      Contact Us
                  </ActiveLink>
                </div>
              </Col>
              <Col xs={12} md={1}>
                <img src={ucbLogo} alt="ucb-logo"/>
              </Col>
               <Col md={11} xs={12} className="rx-block ucb-footer-logo-block">
                <p>
                  VIMPAT<sup>®</sup> is a registered trademark used under license from Harris FRC Corporation. <br/>
                  BRIVIACT<sup>®</sup> is a registered trademark of the UCB Group of&nbsp;Companies.<br/>
                  ©2019&nbsp;UCB, Inc., Smyrna, GA&nbsp;30080. All rights&nbsp;reserved.<br/>
                  US-P-BR-EPOS-1900055
                </p>
              </Col>
            </Row>
            
          </Col>

        </Row>
      </Grid>
    </div>
  </div>
)

export default Footer
